import processors from "@/processors.json";

export const enabledJobTypes = ([] as string[])
	.concat(...processors.map((p) => Object.keys(p.types)))
	.filter(
		(type) =>
			type.startsWith("inference.") &&
			(type.includes("sd15") ||
				type.includes("flux") ||
				(type.includes("mochi") && type.endsWith("v1")) ||
				type.includes("sdxl") ||
				type.includes("faceswap") ||
				type.includes("upscale") ||
				type.includes("recraft.txt2img") ||
				type.includes("inference.kling.txt2vid.v1"))
	);

export const isFreeEnabled = (type: string) =>
	type.startsWith("inference.flux.schnell");
