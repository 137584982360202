import { isPaid, plan } from "@/refs/account";
import router from "@/router";
import { config as arranConfig } from "@/config";
import { key } from "@/refs/account";
import { showProModal } from "@/refs/modals";

import { UAParser } from "ua-parser-js";

const getHostnameString = () => {
	const parser = new UAParser();
	const browserName = parser.getBrowser().name || "Unknown Browser";
	const osName = parser.getOS().name || "Unknown OS";
	return `${browserName} on ${osName}`;
};

export const getExplorerToken = async (): Promise<string> => {
	const cachedToken = localStorage.getItem("explorer-token");

	if (cachedToken) {
		const { token, expires_at } = JSON.parse(cachedToken) as {
			token: string;
			expires_at: string;
		};

		if (new Date(expires_at) > new Date()) {
			return token;
		}
	}

	if (plan.value === undefined) {
		router.push("/login");
		throw new Error("User not logged in");
	}

	if (!isPaid.value) {
		showProModal.value = true;
		throw new Error("User not Pro");
	}

	const expires_at = new Date(Date.now() + 59 * 60 * 1000).toISOString();

	try {
		const response = await fetch(`${arranConfig.arranUrl}/account/token`, {
			method: "POST",
			headers: new Headers({
				"X-Prodia-Key": key.value as string,
				Accept: "application/json",
				"Content-Type": "application/json"
			}),
			body: JSON.stringify({
				label: getHostnameString(),
				expiry: "explorer"
			})
		});

		const { token } = await response.json();

		localStorage.setItem(
			"explorer-token",
			JSON.stringify({
				token,
				expires_at
			})
		);

		return token;
	} catch (err) {}

	alert("Failed to create token. Have you hit your limit?");
	throw new Error("Failed to create token");
};
