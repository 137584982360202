import { email } from "../refs/account";

export const upgradeToPro = () => {
	const url =
		"https://buy.stripe.com/7sI7vAg1Z36M76M5kp" +
		(email.value !== undefined
			? "?prefilled_email=" + encodeURIComponent(email.value)
			: "");

	location.replace(url);
};
