
import { Ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { config } from "@/config";
import {
	key,
	isPaid,
	plan,
	credits,
	isLoggedIn,
	loginCheck
} from "@/refs/account";
import { showProModal, showLoginModal } from "@/refs/modals";
import { getRelativeDate } from "@/lib/relative-date";
import CopyIcon from "@/components/svgs/CopyIcon.vue";
import Receipt from "../components/svgs/Receipt.vue";
import DiscordIconBlue from "@/components/svgs/DiscordIconBlue.vue";
import infoIconBlue from "@/components/infoIconBlue.vue";
import chevronIcon from "@/components/svgs/ChevronIcon.vue";
import DataObjectIcon from "@/components/svgs/DataObjectIcon.vue";
import ChartIcon from "@/components/svgs/ChartIcon.vue";
import TableIcon from "@/components/svgs/TableIcon.vue";
import NewKey from "@/components/svgs/NewKey.vue";

import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

import { ref, computed } from "vue";
import { Bar as BarChart } from "vue-chartjs";

const createLink = (url: string) => () => window.open(url, "_blank")?.focus();

const createCopy = (ref: Ref<string | undefined>) => async () => {
	if (ref.value != undefined) {
		// @ts-ignore
		await navigator.permissions.query({ name: "clipboard-write" });
		await navigator.clipboard.writeText(ref.value);
	}
};

type Usage = {
	period: number;
	generations: number;
}[];

const getUsage = async (): Promise<Usage> => {
	if (key.value === undefined) throw new Error("getUsage(): Key undefined!");

	const response = await fetch(`${config.arranUrl}/account/api-usage`, {
		headers: {
			"X-Prodia-Key": key.value
		}
	});

	return (await response.json()) as Usage;
};

type Token = {
	id: string;
	data: { label: string };
	priority: number;
	created_at: string;
	expires_at: string;
	last_used: string | null;
	revoked_at: string | null;
	role: Record<"job" | "admin" | "worker" | "daemon", boolean | undefined>;
};

const isExplorerToken = (token: Token) => {
	const expiresAt = new Date(token.expires_at);
	const createdAt = new Date(token.created_at);

	// explorer token = less than 2 hours between created_at and expires_at
	return expiresAt.getTime() - createdAt.getTime() < 7.2e6;
};

export default {
	components: {
		BarChart,
		CopyIcon,
		NewKey,
		chevronIcon,
		Receipt,
		DiscordIconBlue,
		DataObjectIcon,
		infoIconBlue,
		ChartIcon,
		TableIcon
	},
	setup: () => {
		const router = useRouter();

		onMounted(async () => {
			await loginCheck;

			if (isLoggedIn.value === false) {
				router.push("/login");
			}
		});

		const usage = ref<Usage | undefined>();

		const maxCredits = credits;

		const currentGens = computed(() => {
			if (
				usage === undefined ||
				usage.value === undefined ||
				usage.value.length === 0
			) {
				return 0;
			}

			const now = new Date();
			const currentPeriod = Date.UTC(now.getFullYear(), now.getMonth());
			const lastValue = usage.value[usage.value.length - 1];
			if (lastValue === undefined || lastValue.period < currentPeriod) {
				return 0;
			}

			return lastValue.generations;
		});

		const currentCreditsUsed = computed(() =>
			currentGens.value >= maxCredits.value
				? maxCredits.value
				: currentGens.value
		);

		const creditsScaleWidth = computed(
			() => `${(currentCreditsUsed.value / maxCredits.value) * 100}%`
		);

		const updateUsage = async () => {
			usage.value = await getUsage();
		};

		// load automatically
		updateUsage();

		// load when plan changes, for instance when login authenticaiton completes
		watch(plan, updateUsage);

		// chart setup

		const isTableView = ref(false);

		const toggleView = () => {
			isTableView.value = !isTableView.value;
		};

		ChartJS.defaults.plugins.legend.display = false;

		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"June",
			"July",
			"Aug",
			"Sept",
			"Oct",
			"Nov",
			"Dec"
		];

		const usageData = computed(() => {
			if (usage.value === undefined) return [];

			return usage.value.map((item) => {
				const date = new Date(item.period);
				return {
					date: `${
						months[date.getUTCMonth()]
					} ${date.getUTCFullYear()}`,
					calls: item.generations || 0
				};
			});
		});

		const usageChart = computed(() => {
			const labels = usageData.value.map((item) => item.date);
			const datasets = [
				{
					data: usageData.value.map((item) => item.calls),
					borderWidth: 1,
					borderRadius: 4,
					backgroundColor: "#5DA9E9"
				}
			];

			const options = {
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						enabled: false
					}
				}
			};

			return { labels, datasets, options };
		});

		const usageChartOptions = {
			responsive: true
		};

		const infoClicked = ref(false);

		const toggleInfo = () => {
			infoClicked.value = !infoClicked.value;
		};

		const v2Tokens = ref<Token[] | undefined>(undefined);

		const sortedTokens = computed(() => {
			if (v2Tokens.value === undefined) return [];

			return v2Tokens.value.sort((a, b) => {
				if (isExplorerToken(a) && !isExplorerToken(b)) return 1;
				if (!isExplorerToken(a) && isExplorerToken(b)) return -1;

				const aDate = new Date(a.created_at);
				const bDate = new Date(b.created_at);

				return aDate.getTime() - bDate.getTime();
			});
		});

		const v2TokenError = ref<string | undefined>();

		const listV2Tokens = async () => {
			await loginCheck;
			if (key.value === undefined || !isPaid.value) return;

			const response = await fetch(`${config.arranUrl}/account/token`, {
				method: "GET",
				headers: new Headers({
					"X-Prodia-Key": key.value,
					Accept: "application/json"
				})
			});

			v2Tokens.value = await response.json();
		};

		const v2TokenLabel = ref("");
		const newV2Token = ref<string | undefined>();

		const createNewV2Token = async () => {
			if (
				v2TokenLabel.value === "" ||
				key.value === undefined ||
				v2Tokens.value === undefined ||
				!isPaid.value
			)
				return;

			const response = await fetch(`${config.arranUrl}/account/token`, {
				method: "POST",
				headers: new Headers({
					"X-Prodia-Key": key.value,
					Accept: "application/json",
					"Content-Type": "application/json"
				}),
				body: JSON.stringify({
					label: v2TokenLabel.value
				})
			});

			if (response.status < 200 || response.status > 299) {
				v2TokenError.value =
					"Error: You have hit the maximum number of active and revoked tokens.";

				return;
			}

			newV2Token.value = (await response.json()).token;
			v2TokenLabel.value = "";

			await listV2Tokens();
		};

		const revokeV2Token = async (token: Token) => {
			if (key.value === undefined || !isPaid.value) return;

			const response = await fetch(
				`${config.arranUrl}/account/token/${token.id}/revoked`,
				{
					method: "PUT",
					headers: new Headers({
						"X-Prodia-Key": key.value,
						Accept: "application/json",
						"Content-Type": "application/json"
					}),
					body: JSON.stringify(true)
				}
			);

			await listV2Tokens();
		};

		listV2Tokens();

		const copyNewV2Token = async () => {
			if (newV2Token.value === undefined) return;

			// @ts-ignore
			await navigator.permissions.query({ name: "clipboard-write" });
			await navigator.clipboard.writeText(newV2Token.value);
			newV2Token.value = undefined;
		};

		return {
			key,
			copyKey: createCopy(key),
			viewDocs: createLink("https://docs.prodia.com/"),
			tryIt: createLink("https://docs.prodia.com/reference/generate"),
			creditsScaleWidth,
			currentCreditsUsed,
			currentGens,
			maxCredits,
			usageChart,
			usageChartOptions,
			isPaid,
			showProModal,
			plan,
			infoClicked,
			toggleInfo,
			isTableView,
			toggleView,
			usageData,
			v2Tokens,
			sortedTokens,
			isExplorerToken,
			v2TokenLabel,
			newV2Token,
			createNewV2Token,
			revokeV2Token,
			copyNewV2Token,
			v2TokenError,
			getRelativeDate
		};
	}
};
